import Image from "next/image";
import Link from "next/link";

import { cn } from "@acme/ui/lib/utils";

interface DownloadAppButtonsProps {
  className?: string;
}

export default function DownloadAppButtons({
  className,
}: DownloadAppButtonsProps) {
  return (
    <div
      className={cn(className, "flex items-center justify-center space-x-5")}
    >
      <Link target="_blank" href="#">
        <Image
          src="/svgs/apple_store.svg"
          alt="Apple store"
          width={150}
          height={40}
          className="w-[150px]"
          priority
        />
      </Link>
      <Link
        target="_blank"
        href={
          "https://play.google.com/store/apps/details?id=com.taro97.cohub42"
        }
      >
        <Image
          src="/svgs/google_store.svg"
          alt="Apple store"
          width={150}
          height={40}
          className="w-[150px]"
          priority
        />
      </Link>
    </div>
  );
}
