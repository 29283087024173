"use client";

import Image from "next/image";
import DownloadAppButtons from "@/components/download-app-buttons";
import { useWaitListStore } from "@/store/use-whaitlist.store";

import TextShimmer from "@acme/ui/components/magicui/animated-shiny-text";
import BoxReveal from "@acme/ui/components/magicui/box-reveal";
import WordRotate from "@acme/ui/components/magicui/word-rotate";
import { Spotlight } from "@acme/ui/components/ui/spotlight";
import { cn } from "@acme/ui/lib/utils";

export default function BannerSection() {
  const { setShowModal } = useWaitListStore();

  return (
    <div className="bg-grid-white/[0.02] relative mb-10 flex min-h-screen w-full overflow-hidden rounded-md bg-white/[0.96] antialiased md:items-center md:justify-center dark:bg-black/[0.96]">
      <Spotlight
        className="-top-40 left-0 md:-top-20 md:left-60"
        fill="#3B82F6"
      />
      <div className="relative top-5 mx-auto flex max-w-4xl flex-col justify-center px-4 text-center md:top-24">
        <div
          className={cn(
            "group mx-auto mb-2 max-w-max rounded-full border border-black/5 bg-neutral-100 text-base text-white transition-all ease-in hover:cursor-pointer hover:bg-neutral-200 dark:border-white/5 dark:bg-neutral-900 dark:hover:bg-neutral-800",
          )}
        >
          <BoxReveal boxColor={"transparent"} duration={0.1} width="100%">
            <TextShimmer className="inline-flex items-center justify-center px-4 py-1 transition ease-out hover:text-neutral-600 hover:duration-300 hover:dark:text-neutral-400">
              <span>✨ Welcome to CoHub42</span>
              {/* <ArrowRightIcon className="ml-1 size-3 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5" /> */}
            </TextShimmer>
          </BoxReveal>
        </div>
        <BoxReveal boxColor={"transparent"} duration={0.2}>
          <h1 className="relative z-10 bg-gradient-to-b from-slate-900 to-zinc-500 bg-clip-text text-center font-sans text-4xl font-bold leading-10 text-transparent md:text-5xl dark:from-neutral-200 dark:to-neutral-600">
            Connect. Collaborate. Create.
          </h1>
        </BoxReveal>
        <BoxReveal boxColor={"transparent"} duration={0.3} width="100%">
          <div className="relative z-10 mx-auto mb-1 mt-2 max-w-2xl text-2xl text-neutral-500">
            CoHub42, The UAE&apos;s Largest Startup Community, Where
            Entrepreneurs Meet Their{" "}
            <span>
              <WordRotate
                className="inline font-bold text-primary"
                words={["Future", "Potential", "Investors", "Partners"]}
              />
            </span>
          </div>
        </BoxReveal>
        {/* <div className="mx-auto mb-10 mt-5">
          <BoxReveal boxColor={"transparent"} duration={0.4}>
            <Button
              borderRadius="1.75rem"
              className="border-neutral-200 bg-primary font-bold text-zinc-300 dark:border-slate-800 dark:bg-background"
              onClick={() => setShowModal(true)}
            >
              Join CoHub42 Today
            </Button>
          </BoxReveal>
        </div> */}
        <BoxReveal boxColor={"transparent"} duration={0.4} width="100%">
          <DownloadAppButtons />
        </BoxReveal>

        <div className="mt-5 flex w-full">
          <BoxReveal boxColor={"transparent"} duration={0.5} width="100%">
            <Image
              src="/images/banner-new.png"
              alt="Apple store"
              width={2000}
              height={2000}
              className="mx-auto h-auto w-full max-w-[700px]"
              priority
            />
          </BoxReveal>
        </div>
      </div>
    </div>
  );
}
