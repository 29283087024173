"use client";

import { useDownloadStore } from "@/store/use-download.store";

import { Button } from "@acme/ui/components/ui/button";

export default function WaitListButton() {
  const { setShowModal } = useDownloadStore();

  return <Button onClick={() => setShowModal(true)}>Get Started &rarr;</Button>;
}
