import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/sections/banner-section.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/components/ui/card-hover-effect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InfiniteMovingCards"] */ "/vercel/path0/apps/web/src/components/ui/infinite-moving-cards.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/components/waitlist-button/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/magicui/box-reveal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider","Tooltip","TooltipTrigger","TooltipContent"] */ "/vercel/path0/packages/ui/src/components/ui/tooltip.tsx");
